import gql from "graphql-tag"

const SEARCH_NEWS_QUERY = gql`
  query SearchQuery($category: String, $order: OrderEnum!) {
    posts(where: {orderby: {field: DATE, order: $order}, tag:$category}) {
      edges {
        node {
          slug
          title(format: RENDERED)
          featuredImage {
            node {
              mediaDetails {
                sizes {
                  sourceUrl
                  width
                  name
                }
              }
            }
          }
      }
    }
  }
}
`
export default SEARCH_NEWS_QUERY

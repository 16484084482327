import * as React from "react"

const IndexPage = () => (

  	 <div className="arrow">

			  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
				  <path id="Path_10" data-name="Path 10" d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z" fill="#fff"/>
			  </svg>

		  </div>

)

export default IndexPage

import React, { useState } from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import getImage from "../functions/getImage"
import Arrow from "../components/symbols/arrow"
import SEARCH_NEWS_QUERY from "../hooks/search-news-query"
import { useQuery } from "react-apollo"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export const query = graphql`
  query {
    allWpTag {
      edges {
        node {
          name
        }
      }
    }
  seoPage:wpPage(slug: {eq: "news"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
  }
`

const IndexPage = ({
  data: {
    seoPage, allWpTag
  },
}) => {

  const [category, setCategory] = useState(' ')
  const [toggleOrder, setToggleOrder] = useState(false)

  const updateCategory = (e) => {
      
      {e.value!=="Reset" ?
        setCategory(e.value)
      :
        setCategory(' ')
      } 

  }

  // populate drop down
  let optionsSection = []
  allWpTag.edges.map((item, i) => (

    optionsSection.push(item.node.name)

  ))

  optionsSection.push('Reset')

  const SearchResults = ({ searchKey }) => {

  let searchOrder
   if(toggleOrder){
    searchOrder="ASC"
   }
   else{
    searchOrder="DESC"
   }

  const { data: queriedData, loading, error } = useQuery(SEARCH_NEWS_QUERY, {
    variables: { order:searchOrder, category: searchKey},
    
  })

  if (error) return `Error! ${error.message}`;

  return(

    <>
    {!!searchKey &&
        queriedData?.posts?.edges &&
        queriedData.posts.edges.map((item, i) => (
          
          item.node.slug &&
          <a rel="noreferrer" href={`/news/${item.node.slug}`} key={i}>
              
          {item.node.featuredImage && item.node.featuredImage.node && item.node.featuredImage.node.mediaDetails &&
          <div className="news-item">
           
            <div className="image" style={{backgroundImage:`url(${getImage(item.node.featuredImage.node.mediaDetails.sizes)})`}}></div>

            <div className="content">

            {item.node.title}

            <div className="read-article">READ BLOG ARTICLE <Arrow /></div>

            </div>
          </div>
          }

          </a>

        ))
      }
    </>
    )

}

  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title="Blog" />
    }
    <div className="simpleText news" style={{backgroundColor:"#2F2F2F"}}>
      
      <h1>Blog</h1>

      <div className="news-grid">

      {category &&
        <SearchResults
          searchKey={category}
        />
      }


      </div>
     

    </div>

   
  </Layout>
)
}

export default IndexPage
